import { Component, OnInit } from '@angular/core';
import { EmailFormService } from '../email-form.service';

@Component({
  selector: 'app-email-ack',
  templateUrl: './email-ack.component.html',
  styleUrls: ['./email-ack.component.css']
})
export class EmailAckComponent implements OnInit {
  firstName: string;

  constructor(private emailFormService: EmailFormService) { }

  ngOnInit() {
    this.emailFormService.userFirstName.subscribe(firstName => this.firstName = firstName);
  }

}
