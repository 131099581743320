import { Component, OnInit } from '@angular/core';
import { SalesforceService } from '../auth/salesforce.service';

@Component({
  selector: 'app-enterprise',
  templateUrl: './enterprise.component.html',
  styleUrls: ['./enterprise.component.css']
})
export class EnterpriseComponent implements OnInit {

  constructor() { 
    console.log('Invoking salesforceService');
    let service = new SalesforceService();
  }

  ngOnInit() {
  }

}
