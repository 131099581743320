export const environment = {
  production: true,
  smtpHost: 'https://pqtprodtoolslivechat.nad.gcp.iaglab.com',
  port: 3000,
  accountChange: 'npsrt@hughes.com',
  billing: 'npsrt@hughes.com',
  other: 'npsrt@hughes.com',
  tech: 'npsrt@hughes.com',
  voice: 'npsrt@hughes.com'
};
