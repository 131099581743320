import {Injectable} from "@angular/core";
import '@salesforce/canvas-js-sdk';

declare var Sfdc: any;

@Injectable({
  providedIn: "root",
})
export class SalesforceService {
  public signedRequest: any;
  public instanceUrl: string;
  public locationUrl: string;
  public queryUrl: string;
  public oauthToken: string;
  public refresh_complete: boolean = false;
  public userName: string;
  public companyId: string;
  public email: string;
  public name: string;

  constructor() {
    console.log("Salesforce constructor", new Date());
    Sfdc.canvas.client.refreshSignedRequest(
      this.getSignedRequestData.bind(this)
    );
  }

  getSignedRequestData(signed_request) {
    if (signed_request.status === 200) {
      let signedRequestObj = signed_request.payload.response;
      this.signedRequest = JSON.parse(
        Sfdc.canvas.decode(signedRequestObj.split(".")[1])
      );
      console.log(JSON.stringify(this.signedRequest));
      let parameters = this.signedRequest.context.environment.parameters;
      if (parameters) {
        console.log(parameters);
        this.userName = this.signedRequest.context.user.userName;
        this.companyId = parameters["Company Id"];
        this.email = parameters["Email"];
        this.name = parameters["name"];
      }

      let application = this.signedRequest.context.application;

      let client = this.signedRequest.client;
      if (client) {
        this.oauthToken = client.oauthToken;
        this.instanceUrl = client.instanceUrl;
      }

      let formWebChatRequest = {
        CustEmail: this.email,
        CustName: this.name,
        UserDefined1: this.companyId,
        Timezone: "-240"
      };

      sessionStorage.setItem("ChatReq", JSON.stringify(formWebChatRequest));
      //Re-visit this.
      sessionStorage.setItem("HONFirstChat", this.name + " joined the chat.");
      const path = '/assets/enterprise/ChatRequestLongPolling.html';
      console.log('Path: ' + path);
      window.location.assign(path);
      this.refresh_complete = true;
    }
  }
}
