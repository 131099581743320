import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs'; 

@Injectable({
  providedIn: 'root'
})
export class EmailFormService {
  private fnameSource = new BehaviorSubject<string>("");
  userFirstName = this.fnameSource.asObservable();

  constructor() { }

  setFname(firstName: string) {
    this.fnameSource.next(firstName)
  }
}
