import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnvService {

  // Special property
  // This is overwritten and set to true by env.js.
  public envFileLoaded = false;

  // The values that are defined here are the default values that can
  // be overridden by env.js

  public production = true;
  public smtpHost = 'https://pqtprodtoolslivechat.nad.gcp.iaglab.com';
  public port = 3000;
  public accountChange = '';
  public billing = '';
  public other = '';
  public tech = '';
  public voice = '';

  constructor() { }
}
