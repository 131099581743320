import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EmailAckComponent } from './email/email-ack/email-ack.component';
import { EmailComponent } from './email/email.component';

const routes: Routes = [
    {path: 'email' , component: EmailComponent},
    {path: 'email-ack' , component: EmailAckComponent}
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }