import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EmailFormService } from './email-form.service';
import {Constants} from '../constants/constants';
import { EnvService } from '../services/env.service';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.css']
})
export class EmailComponent implements OnInit {
  formdata: FormGroup;
  questions = [{ id: 1, name: "Question about my internet service" },
  { id: 2, name: "Question about my bill or payment" },
  { id: 3, name: "I need to make a change to my account" },
  { id: 4, name: "Question about my voice service" },
  { id: 5, name: "I need help with something else" }];
  
  firstName;
  lastName;
  san;
  emailId;
  comment;
  emailAckData;
  toEmailAddr = this.env.other;

  constructor(private http: HttpClient, private fb: FormBuilder, private router: Router, private emailFormService: EmailFormService, private env: EnvService) { }

  ngOnInit() {
    this.formdata = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      san: ['', Validators.required],
      emailId: ['', [Validators.required, Validators.email]],
      question: ['', Validators.required],
      comment: ['', Validators.required]
    });
  }

  onClickSubmit() {
    if (this.formdata.invalid) {
      return;
  }
    // console.log("Sumbit button function: " + JSON.stringify(this.formdata.value));
    let data = this.formdata.value;
    // this.goToEmailAck(data.firstName);
    this.sendEmail(data);
  }

  goToEmailAck(name) {
    this.emailFormService.setFname(name);
    this.router.navigate(['email-ack']);
  }

  sendEmail(data) {
    if (data.question === 'Question about my internet service') {
      this.toEmailAddr = this.env.tech;
    } else if (data.question === 'Question about my bill or payment') {
      this.toEmailAddr = this.env.billing
    } else if (data.question === 'I need to make a change to my account') {
      this.toEmailAddr = this.env.accountChange
    } else if (data.question === 'Question about my voice service') {
      this.toEmailAddr = this.env.voice
    } else if (data.question === 'I need help with something else') {
      this.toEmailAddr = this.env.other
    }
    this.http.post<any>(`${this.env.smtpHost}/sendMail`,
          { firstName: data.firstName, 
            lastName: data.lastName,
            san: data.san,
            email: data.emailId,
            subject: data.question,
            question: data.question,
            toEmailAddr: this.toEmailAddr,
            comment: data.comment}).subscribe(response => {
            console.log(response);
        })
    this.goToEmailAck(data.firstName);
  }
}
