import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { EnterpriseComponent } from './enterprise/enterprise.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Support';

  constructor(private router: Router) {}

  ngOnInit() {
    console.log('Inside Routing Http Request!!!');
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const businessUnit = urlParams.get('bu');
    const serviceType = urlParams.get('st');
    console.log('BusinessUnit: ' + businessUnit);
    if (businessUnit === "ent") {
      let enterpriseComponent = new EnterpriseComponent();
      console.log(enterpriseComponent);
    } else if (businessUnit === "con") {
      if(serviceType == "email") {
        this.router.navigate(['/email']);
      }
      else {
        const path = '/assets/consumer/Captcha.html';
        console.log('Path: ' + path);
        window.location.assign(path);
      }
    }
  }
}
